import Axios from "./caller.service";

let createPost = (data) => {
  return Axios.post("/api/posts", data);
};

let addComment = (data) => {
  return Axios.post("/api/Comments", data);
};

let deletePost = (postId) => {
  return Axios.delete(`/api/posts/${postId}`);
};

let searchComments = (data) => {
  return Axios.post("/api/Comments/search", data);
};

let getAllPosts = () => {
  return Axios.get("/api/posts");
};

let changeUserReaction = (data) => {
  return Axios.post("/api/posts/reaction", data);
};

let deleteUserReaction = (postId) => {
  return Axios.delete(`/api/posts/${postId}/reaction`);
};

export const socialService = {
  getAllPosts,
  createPost,
  addComment,
  deletePost,
  searchComments,
  changeUserReaction,
  deleteUserReaction
};

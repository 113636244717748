import Axios from "./caller.service";

const getBillById = (id) => {
  const response = Axios.get("/api/invoice/get/" + id);
  return response;
}

let getAllBillsByUserId = (userId) => {
  const response = Axios.get("/api/invoice/all/" + userId);
  return response;
};

let addBill = (invoice) => {
  const response = Axios.post("/api/invoice/add",
    invoice
  );

  return response;
};

let updateBill = (invoice) => {
  const response = Axios.post("/api/invoice/update",
    invoice
  );

  return response;
};

export const billingService = {
  getAllBillsByUserId,
  getBillById,
  addBill,
  updateBill
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import SvgIcon from "@material-ui/core/SvgIcon";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import i18next from "i18next";
import {
  Add,
  Edit,
  Delete,
  Navigation,
  Print,
  Save,
  WarningRounded,
  Sync,
  Search,
  CameraAlt,
  Visibility,
  VisibilityOffOutlined,
  MoreHoriz,
  CalendarTodayTwoTone,
  NotificationsActiveTwoTone,
  TodayTwoTone,
  CloseTwoTone,
  FileCopyOutlined,
  HelpOutlined,
  CloudDownload,
  Settings
} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import LoadingButton from "@mui/lab/LoadingButton";
export {
  Add,
  Edit,
  Close,
  Navigation,
  Print,
  Save,
  WarningRounded,
  Sync,
  Search,
  CameraAlt,
  Visibility,
  VisibilityOffOutlined,
  MoreHoriz,
  Schedule,
  CalendarTodayTwoTone,
  NotificationsActiveTwoTone,
  TodayTwoTone,
  CloseTwoTone,
  AttachFile,
  AddOutlined,
  KeyboardArrowDown, KeyboardArrowUp, Menu
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > svg": {
      margin: theme.spacing(2),
    },
  },
  iconHover: {
    "&:hover": {
      color: red[800],

    },
  },
  iconvisibilityHover: {
    "&:hover": {
      color: "#b0b0b0",
      cursor: "pointer",
      padding: "0px",
    },
  },
  customFocus: {
    "&:focus": { outline: "none", "box-shadow": "none" },
    padding: "0px",
    fontSize: "20px"
  },
  customLink: {
    "&:focus": { outline: "none", "box-shadow": "none" },
    padding: "0px",
    fontSize: "20px",
    color: "#007bff"
  },
}));

export function AddIcon(props) {
  return (
    <Tooltip title={i18next.t("Add")}>
      <Fab size="small" color="primary" aria-label="add" {...props}>
        <Add />
      </Fab>
    </Tooltip>
  );
}

export function ContentCopyIcon(props) {
  return (
    <Tooltip title={i18next.t("Click to copy")}>
      <FileCopyOutlined {...props} />
    </Tooltip>
  );
}

export function SyncIcon(props) {
  const styles = useStyles().customLink;
  if (props.tooltip)
    return (
      <Tooltip title={props.tooltip}>
        <span>
          <IconButton
            size="medium"
            className={styles}
            aria-label="refresh"
            {...props}
          >
            <Sync fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
    );
  return <span>
    <IconButton
      size="medium"
      className={styles}
      aria-label="refresh"
      {...props}
    >
      <Sync fontSize="inherit" />
    </IconButton>
  </span>
}

export function InfoIcon(props) {
  return (
    <Tooltip title={props.tooltip}>
      <span>
        <IconButton
          size="small"
          aria-label="info"
          {...props}
        >
          <HelpOutlined fontSize="inherit" />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export function LoadingIcon() {
  return <LoadingButton loading={true} />;
}

export function ExpendMoreIcon(props) {
  if (props.tooltip)
    return (
      <Tooltip title={props.tooltip}>
        <MoreHoriz {...props} />
      </Tooltip>);
  return <MoreHoriz {...props} />
}

export function SearchIcon() {
  return <Search />;
}

export function DownloadIcon(props) {
  return <CloudDownload {...props} />
}

export function CalendarIcon(props) {
  return <CalendarTodayTwoTone {...props} />;
}

export function NotificationsActiveIcon(props) {
  return <NotificationsActiveTwoTone {...props} />;
}

export function TodayIcon(props) {
  return <TodayTwoTone {...props} />;
}

export function SettingsIcon(props) {
  return <Settings {...props} />;
}


export function CloseIcon(props) {
  return <CloseTwoTone style={{ cursor: "pointer" }} />
}

export function VisibilityIcon(props) {
  const styles = useStyles().customFocus;
  if (props.open)
    return <IconButton
      size="medium"
      className={styles}
      aria-label="show"
      {...props}
    >
      <Visibility {...props} fontSize="inherit" />
    </IconButton >
  else
    return <IconButton
      size="medium"
      className={styles}
      aria-label="hide"
      {...props}
    ><VisibilityOffOutlined {...props} fontSize="inherit" /></IconButton>
}

export function EditIcon(props) {
  return (
    <Tooltip title={i18next.t("Edit")}>
      <Fab size="small" color="secondary" aria-label="edit" {...props}>
        <Edit />
      </Fab>
    </Tooltip>
  );
}

export function EditOnlyIcon(props) {
  return (
    <Tooltip title={i18next.t("Edit")}>
      <span>
        <IconButton
          size="medium"
          className={useStyles().customFocus}
          aria-label="refresh"
          {...props}
        >
          <Edit fontSize="inherit" />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export function AddOnlyIcon(props) {
  return (
    <Tooltip title={i18next.t("Add")}>
      <span>
        <IconButton
          size="medium"
          className={useStyles().customFocus}
          aria-label="add"
          {...props}
        >
          <Add fontSize="inherit" />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export function CameraIcon(props) {
  return (
    <Tooltip title={i18next.t("Upload photo")}>
      <Fab size="small" color="extended" aria-label="upload photo" {...props}>
        <CameraAlt />
      </Fab>
    </Tooltip>
  );
}

export function DeleteIcon(props) {
  return (
    <Tooltip title={i18next.t("Delete")}>
      <Fab size="small" color="extended" aria-label="delete" {...props}>
        <Delete />
      </Fab>
    </Tooltip>
  );
}

export function DeleteWhitoutFab(props) {
  return (
    <Tooltip title={i18next.t("Delete")}>
      <Delete style={{ cursor: "pointer" }} {...props} />
    </Tooltip>
  );
}

export function NavigationIcon(props) {
  return (
    <Tooltip title={i18next.t("Navigate")}>
      <Fab size="small" color="primary" aria-label="Navigate" {...props}>
        <Navigation />
      </Fab>
    </Tooltip>
  );
}

export function PrintIcon(props) {
  return (
    <Tooltip title={props.title}>
      <Fab size="small" color="primary" aria-label="Print" {...props}>
        <Print />
      </Fab>
    </Tooltip>
  );
}

export function HomeIcon(props) {
  return (
    <SvgIcon {...props} className={useStyles().iconHover}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

export function WarningIcon(props) {
  return (
    <Tooltip title={i18next.t("Warning")}>
      <WarningRounded {...props} />
    </Tooltip>
  );
}

export function SaveIcon(props) {
  return (
    <Tooltip title={i18next.t("Save")}>
      <Fab size="small" color="primary" aria-label="Save" {...props}>
        <Save />
      </Fab>
    </Tooltip>
  );
}

// export function PrintIcon(props) {
//   return (
//     <SvgIcon {...props} color="primary" className={useStyles().iconHover}>
//       <path d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z" />
//     </SvgIcon>
//   );
// }

export function CancelIcon(props) {
  return (
    <SvgIcon {...props} color="secondary" className={useStyles().iconHover}>
      <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
    </SvgIcon>
  );
}

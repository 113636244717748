import { useEffect, useState } from "react";
import ReactionButton from "./ReactionButton";
import ReactionSelectorComponent from "./ReactionSelectorComponent";

export default function ReactionButtonWithSelector(props) {
    const { id, reaction, handleChangeUserReaction , selectorStyle = null} = props;
    const [isReactionsOpen, setIsReactionsOpen] = useState(false);
    const [hoveringLike, setHoveringLike] = useState(false);
    const [hoveringReactions, setHoveringReactions] = useState(false);
    const [animateReaction, setAnimateReaction] = useState(false);

    const showReactions = () => setIsReactionsOpen(true);

    const handleReaction = () => {
        setAnimateReaction(true); // Trigger animation in child
        setTimeout(() => {
            setAnimateReaction(false); // Reset animation after 1 second
        }, 1000);
    };

    useEffect(() => {
        if (!hoveringReactions && !hoveringLike) {
            setIsReactionsOpen(false);
        }
    }, [hoveringReactions, hoveringLike]);

    const handleMouseLeaveLike = () => {
        setHoveringLike(false);
    };

    const handleMouseLeaveReactions = () => {
        setHoveringReactions(false);
      };

    return (
        <>
            {isReactionsOpen === true && (
                <div
                    id={id}
                    className="floating-div"
                    onMouseLeave={handleMouseLeaveReactions}
                    onMouseEnter={() => setHoveringReactions(true)}
                    style={selectorStyle}
                >
                    <ReactionSelectorComponent onChangeReaction={handleChangeUserReaction} animate={animateReaction} />
                </div>
            )}

            <ReactionButton
                handleChangeUserReaction={handleChangeUserReaction}
                handleMouseEnter={() => {
                    setHoveringLike(true);
                    showReactions();
                    handleReaction();
                }}
                handleMouseLeaveLike={handleMouseLeaveLike}
                reaction={reaction}
            />
        </>
    );
}
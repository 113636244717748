/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import i18next from "i18next";
import Carousel from "components/Carousel/Carousel";
import Bills from "./Bills";
import Bill from "./Bill";
import { NotificationManager } from "react-notifications";
import { getTime } from "utils/date";
import Preview from "./preview";
import { Downloader } from "components/Pdf/downloader";
import GenericHeader from "components/Headers/GenericHeader";
import { Container } from "reactstrap";
import { billingService } from "utils/_services/billing.services";

import { getformatedDate } from "utils/_helpers/dateUtils";
import { isObjectEmpty } from "utils/objects";
import useFetchUser from "hooks/useFetchUser";

const billsSlideIndex = 0;
const billSlideIndex = 1;
const Main = () => {

  const user = useFetchUser();

  const [bills, setBills] = useState([]);
  const [selectedBill, setSelectedBill] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const [activeSlide, setActiveSlide] = useState(
    id ? billSlideIndex : billsSlideIndex
  );
  const nextSlide = () =>
    setActiveSlide(activeSlide < 1 ? activeSlide + 1 : activeSlide);
  const previousSlide = () =>
    setActiveSlide(activeSlide > 0 ? activeSlide - 1 : activeSlide);

  // const prevActivdSlideRef = useRef();
  // const notify = (isSuccess, message) => {
  //   // dispatch(
  //   //   setNotification({
  //   //     isSuccess: isSuccess,
  //   //     message: message,
  //   //   })
  //   // );
  // };

  useEffect(() => {
    try {
      // dispatch(setLoading(true));
      if (user) {
        loadData(user.id);
      }
    } catch (error) {
      // dispatch(setLoading(false));
      // notify(false, error);
    }
    // eslint-disable-next-line
  }, [user]);

  const loadData = (userId) => {
    if (id) {
      billingService.getBillById(id).then(response => {
        setSelectedBill(enrichBill(response.data));
      });
      // dispatch(setLoading(false));
    }

    billingService.getAllBillsByUserId(userId).then(response => {
      setBills(response.data);
    });
    // dispatch(setLoading(false));
  };


  // useEffect(
  //   () => {
  //     prevActivdSlideRef.current = activeSlide;
  //   },
  //   // eslint-disable-next-line
  //   [activeSlide]
  // );

  const enrichBill = (item) => {
    return {
      ...item,
      ...{
        commercialName: user.company?.name,
        address: user.company?.address,
        mail: user.mail,
        phone: user.phoneNumber,
        siret: user.company?.siretNumber,
        tvaNumber: user.company?.tvaNumber ?? "non fournie",
        logo: user.company?.logo?.url,
        signature: user.company?.signature?.url,
      }
    };
  }

  const onRowClick = (index) => {
    setSelectedBill(enrichBill(bills[index]));
    setSelectedIndex(index);
    nextSlide();
  };

  const onAddClick = async (index, item) => {
    let initItem = {};
    if (index > -1) {
      initItem = bills[index];
    } else {
      initItem.priceHt = 0;
      initItem.penaltyRate = 0;
      initItem.currency = "€";
      initItem.tva = 20;
      initItem.includeTva = true;
      initItem.includeLogo = true;
      initItem.includePenalty = false;
      initItem.state = "unpaid";
      initItem.reference = item.reference;
      initItem.clientName = "";
      initItem.clientAddress = "";
      initItem.serviceExecutionDate = getformatedDate();
      initItem.creationDate = getformatedDate();
      initItem.quantity = 0;
      initItem.totalPrice = 0;
      initItem.lines = [];
    }
    setSelectedBill(enrichBill(initItem));
    setSelectedIndex(index);
    nextSlide();
  };

  const onDeleteClick = async (index) => {
    // await remove(bills[index].id);
    // const newBills = [...bills];
    // newBills.splice(index, 1);
    // if (bills[index].collectDate !== 0)
    //   dispatch(
    //     fetchStatistics([new Date(bills[index].collectDate).getFullYear()])
    //   );
    // else
    //   dispatch(
    //     fetchStatistics([new Date(bills[index].paimentDate).getFullYear()])
    //   );
    // setBills(newBills);
    // dispatch(setSampleBills(newBills.slice(0, 5)));
    // dispatch(fetchTransactions());

    // notify(true, i18next.t("successfully deleted"));
  };

  const onSaveClick = async (index, data) => {
    try {
      //   // dispatch(setLoading(true));
      const newBills = [...bills];
      if (index === -1) {
        const bill = await billingService.addBill(data);
        NotificationManager.success(
          "La facture a été ajoutée avec succes"
        );
      } else {
        const bill = await billingService.updateBill(data);
        NotificationManager.success(
          "La facture a été mis à jour avec succes"
        );
      }

    } catch (e) {
      console.log(e.message);
    }
    finally {

    }
  };

  const generatePdf = (item) => {
    const completeItem = enrichBill(item);
    return { title: `${i18next.t("bill")}-${completeItem.commercialName}-${getTime()}.pdf`, page: < Preview item={completeItem} /> };
  }

  const onDownload = async (item) => {
    Downloader(generatePdf(item))
  }

  const items = [
    {
      id: billsSlideIndex,
      component: (
        <Bills
          data={bills}
          handleRowClick={onRowClick}
          handleInit={onAddClick}
          handleDeleteClick={onDeleteClick}
          handleDownload={onDownload}
          generatePdf={generatePdf}
          handleSave={onSaveClick}
        />
      ),
    },
    {
      id: billSlideIndex,
      component: !isObjectEmpty(selectedBill) && (
        <Bill
          item={selectedBill}
          handleBackClick={previousSlide}
          index={selectedIndex}
          handleSave={onSaveClick}
        />
      ),
    },
  ];

  return (<>
    <GenericHeader />
    <Container style={{ maxWidth: "2000px", marginTop: "25px" }}>
      <Carousel items={items} activeIndex={activeSlide} />
    </Container>
  </>)
}

export default Main; 

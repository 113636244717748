import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import "./ReactionSelectorComponent.css";
import ClapEmoji from 'assets/img/social/clap-emoji.png';
import LikeEmoji from 'assets/img/social/like-emoji.png';
import HeartEmoji from 'assets/img/social/heart-emoji.png';
import SmileEmoji from 'assets/img/social/smile-emoji.png';

const ReactionSelectorComponent = ({ onMouseEnter, onMouseLeave, onChangeReaction, animate }) => {
    const [reaction, setReaction] = useState(null); // Store the selected reaction

    // Separate state for each tooltip visibility
    const [likeTooltipOpen, setLikeTooltipOpen] = useState(false);
    const [heartTooltipOpen, setHeartTooltipOpen] = useState(false);
    const [congratulationTooltipOpen, setCongratulationTooltipOpen] = useState(false);
    const [smileTooltipOpen, setSmileTooltipOpen] = useState(false);

    // Toggle functions for each tooltip
    const toggleLikeTooltip = () => setLikeTooltipOpen(!likeTooltipOpen);
    const toggleHeartTooltip = () => setHeartTooltipOpen(!heartTooltipOpen);
    const toggleCongratulationTooltip = () => setCongratulationTooltipOpen(!congratulationTooltipOpen);
    const toggleSmileTooltip = () => setSmileTooltipOpen(!smileTooltipOpen);

    const handleReaction = (newReaction) => {
        const changedReaction = reaction === newReaction ? null : newReaction;
        setReaction(changedReaction);
        onChangeReaction(changedReaction);
    };

    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={`reaction-container ${animate ? "animate" : ""}`}>
            <img
                id="like-btn"
                src={LikeEmoji}
                onClick={() => handleReaction('like')}
                alt="J'aime"
                className={`like-emoji reaction-image`}
                style={{ width: "30px", height: "30px" }} />

            <Tooltip
                placement="top"
                isOpen={likeTooltipOpen}
                target="like-btn"
                toggle={toggleLikeTooltip}
                popperClassName="custom-tooltip"
            >
                J'aime
            </Tooltip>

            <img
                id="heart-btn"
                src={HeartEmoji}
                alt="J'adore"
                onClick={() => handleReaction('heart')}
                className="heart-emoji reaction-image"
                style={{ width: "30px", height: "30px" }} />

            <Tooltip
                isOpen={heartTooltipOpen}
                target="heart-btn"
                toggle={toggleHeartTooltip}
                popperClassName="custom-tooltip"
            >
                J'adore
            </Tooltip>
            
            <img
                id="congratulation-btn"
                onClick={() => handleReaction('congratulation')}
                src={ClapEmoji}
                alt="Bravo"
                className="congratulation-emoji reaction-image"
                style={{ width: "30px", height: "30px" }} />

            <Tooltip
                isOpen={congratulationTooltipOpen}
                target="congratulation-btn"
                toggle={toggleCongratulationTooltip}
                popperClassName="custom-tooltip"
            >
                Bravo
            </Tooltip>

            <img
                id="smile-btn"
                onClick={() => handleReaction('smile')}
                src={SmileEmoji}
                alt="Drôle"
                className="smile-emoji reaction-image"
                style={{ width: "30px", height: "30px" }} />

            <Tooltip
                isOpen={smileTooltipOpen}
                target="smile-btn"
                toggle={toggleSmileTooltip}
                popperClassName="custom-tooltip"
            >
                Drôle
            </Tooltip>

        </div>
    );
};

export default ReactionSelectorComponent;

import React, { useEffect, useState } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Row
} from 'reactstrap'
import Pdf from "components/Pdf";
import Preview from './preview';
import GenerateFormInput from 'components/Ui/GenerateFormInput';
import ToggleSwitch from "components/Forms/inputs/SwitchWithText";
import i18next from 'i18next';
import NavLink from "components/Forms/others/Navlink";
import { getformatedDate } from 'utils/_helpers/dateUtils';
import QuoteLines from './QuoteLines';


export default function Quote(props) {
    const { handleBackClick, item, handleSave, index } = props;
    const [editItem, setEditItem] = useState(item);

    useEffect(() => {
        console.log("Quote reloaded");
    }, [])

    useEffect(() => {
        console.log("Quote currrent item changed");
        const currentItem = { ...item };
        currentItem.creationDate = currentItem.creationDate ? getformatedDate(currentItem.creationDate) : getformatedDate();
        currentItem.serviceExecutionDate = currentItem.serviceExecutionDate ? getformatedDate(currentItem.serviceExecutionDate) : getformatedDate();
        currentItem.lines = (currentItem.lines && currentItem.lines.length > 0) ? currentItem.lines : [{ serviceName: '', quantity: 1, priceHt: 0, totalPriceHt: 0, tvaPercentage: 0, tvaAmount: 0 }];
        setEditItem(currentItem);
        // eslint-disable-next-line
    }, [item]);

    const onChange = (e) => {
        const { name, type, value, checked } = e.target;

        let updatedValue = type === "checkbox" ? checked : (type === "number" ? Number(value) : value);

        setEditItem({
            ...editItem,
            [name]: updatedValue,
        });
    };

    const handleLineChange = (items) => {
        console.log(items);
        // Update the invoice lines (items) when the FactureForm changes
        setEditItem({
            ...editItem,
            lines: items,
            totalPriceHt: items.reduce((acc, line) => acc + line.totalPriceHt, 0),
            totalPrice: items.reduce((acc, line) => acc + line.totalPriceHt + line.tvaAmount, 0),
            tvaAmount: items.reduce((acc, line) => acc + line.tvaAmount, 0),
        });
    };

    return (
        <>
            <Row>
                <Col md={5}>

                    <Col md={12} style={{ paddingBottom: "10px" }}>
                        <NavLink
                            title={i18next.t("< Retour aux devis")}
                            action={handleBackClick}
                            to={"#"}
                        />
                    </Col>

                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                                <Col xs="12">
                                    <h3 className="mb-0 text-center">Informations du devis</h3>
                                </Col>

                            </Row>
                        </CardHeader>
                        <CardBody style={{ maxHeight: "calc(-300px + 100vh)", overflow: "auto" }}>
                            <h6 className="heading-small text-muted mb-2">
                                Entête
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="12">
                                        <GenerateFormInput
                                            forInput="input-last-name"
                                            label="Réference (numéro du devis)"
                                            editModeClassName={true}
                                            value={editItem.reference}
                                            onChange={onChange}
                                            name="reference"
                                            type="text"
                                            placeholder="..."
                                            disabled={false}
                                            required={true}
                                        />

                                    </Col>

                                </Row>
                                <Row>
                                    <div className="col-md-12">
                                        <GenerateFormInput
                                            forInput="input-creationDate"
                                            label="Date du devis"
                                            editModeClassName={true}
                                            value={editItem.creationDate}
                                            onChange={onChange}
                                            name="creationDate"
                                            type="date"
                                            placeholder="Date du devis.."
                                            disabled={false}
                                        />
                                    </div>
                                </Row>                               
                            </div>

                            <hr className="my-1" />
                            <h6 className="heading-small text-muted mb-2">
                                Informations du client
                            </h6>

                            <div className="pl-lg-4">
                                <Row>
                                    <div className="col-md-12">
                                        <GenerateFormInput
                                            forInput="input-customer"
                                            label="Nom ou raison social du client"
                                            editModeClassName={true}
                                            value={editItem.clientName}
                                            onChange={onChange}
                                            name="clientName"
                                            type="text"
                                            placeholder="Nom ou raison social du client.."
                                            disabled={false}
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-12">
                                        <GenerateFormInput
                                            forInput="input-customer"
                                            label="Adresse du client"
                                            editModeClassName={true}
                                            value={editItem.clientAddress}
                                            onChange={onChange}
                                            name="clientAddress"
                                            type="text"
                                            placeholder="Adresse du client.."
                                            disabled={false}
                                        />
                                    </div>
                                </Row>
                            </div>

                            <hr className="my-1" />
                            <h6 className="heading-small text-muted mb-2">
                                Options
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                    <div className="col-md-6">
                                        <ToggleSwitch
                                            field={editItem.includeLogo}
                                            placeholder={"Inclure le logo"}
                                            name={"includeLogo"}
                                            forInput="logo"
                                            isReadOnly={true}
                                            checkedText={"Inclure le logo"}
                                            unCheckedText={"Inclure le logo"}
                                            value={editItem.includeLogo}
                                            handleChange={onChange}
                                        />
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <ToggleSwitch
                                            field={editItem.includeSignature}
                                            placeholder={"Inclure la signature"}
                                            name={"includeSignature"}
                                            forInput="signature"
                                            isReadOnly={true}
                                            checkedText={"Inclure la signature"}
                                            unCheckedText={"Inclure la signature"}
                                            value={editItem.includeSignature}
                                            handleChange={onChange}
                                        />
                                    </div>
                                    {/* {editItem.includeTva === true &&
                                        <div className="col-md-6">
                                            <InputGroup>
                                                <Input type="number" value={editItem.tva} name="tva" onChange={onChange} style={{ maxWidth: "80px" }} />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>%</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>} */}
                                </Row>
                            </div>

                            <hr className="my-1" />
                            <h6 className="heading-small text-muted mb-2">Lignes du devis</h6>
                            <QuoteLines
                                items={editItem.lines}  // Pass the current invoice lines to FactureForm
                                onItemsChange={handleLineChange}  // Callback to handle item changes
                            />       
                             <hr className="my-1" />
                            <h6 className="heading-small text-muted mb-2">
                                Détails bancaires
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                    <div className="col-md-12">
                                        <GenerateFormInput
                                            forInput="input-customer"
                                            label="Nom de la banque"
                                            editModeClassName={true}
                                            value={editItem.bankName}
                                            onChange={onChange}
                                            name="bankName"
                                            type="text"
                                            placeholder="Nom de la banque.."
                                            disabled={false}
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-12">
                                        <GenerateFormInput
                                            forInput="input-customer"
                                            label="IBAN"
                                            editModeClassName={true}
                                            value={editItem.bankReference}
                                            onChange={onChange}
                                            name="bankReference"
                                            type="text"
                                            placeholder="IBAN.."
                                            disabled={false}
                                        />
                                    </div>
                                </Row>
                            </div>                     
                        </CardBody>
                        <CardFooter>
                            <div className="d-flex justify-content-end">
                                <Button
                                    className="btn btn-success cursor-pointer"
                                    title="Sauvegarder"
                                    onClick={() => handleSave(index, editItem)}
                                >
                                    Sauvegarder
                                </Button>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
                <Col md={7}>
                    <Pdf title={`Devis `} page={<Preview item={editItem} />} />
                </Col>
            </Row>
        </>)
}

import Axios from "./caller.service";

const getQuoteById = (id) => {
  const response = Axios.get("/api/quote/get/" + id);
  return response;
}

let getAllQuotesByUserId = (userId) => {
  const response = Axios.get("/api/quote/all/" + userId);
  return response;
};

let addQuote = (quote) => {
  const response = Axios.post("/api/quote/add",
    quote
  );

  return response;
};

let updateQuote = (quote) => {
  const response = Axios.post("/api/quote/update",
    quote
  );

  return response;
};

export const quoteService = {
  getAllQuotesByUserId,
  getQuoteById,
  addQuote,
  updateQuote
};

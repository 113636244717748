import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./SignatureScreen.css"; // Import the external CSS file
import { removeUrlPartFromContentImage } from "utils/fileloader";

const SignatureScreen = ({ content, imageURL, onChangeImage }) => {
  const [imageData, setImageData] = useState(content ?? imageURL ?? '');

  useEffect(() => {
    if (imageURL) {
      setImageData(imageURL);
    }
  }, [imageURL]);

  // useEffect(() => {
  //   if (content !== null && content !== undefined) {
  //     setImageData(content);
  //   }
  // }, [content]);
  // To store the uploaded image
  const sigCanvas = useRef({});

  // To clear the drawn signature
  const clearSignature = () => {
    setImageData(null);
    sigCanvas?.current?.clear();
    onChangeImage({ content: null, name: null });
  };

  // To save the drawn signature
  const saveSignature = () => {
    if (!sigCanvas.current.isEmpty()) {
      const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      onChangeImage({ content: removeUrlPartFromContentImage(dataURL) });
      setImageData(dataURL);
    }
  };

  // To handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        let dataContent = reader.result;
        onChangeImage({ content: removeUrlPartFromContentImage(dataContent), name: file.name });
        setImageData(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="text-center" >
      <label className="title form-control-label">
        Signature de votre entreprise
      </label>
      {/* <h2 className="title">Signature de votre entreprise</h2> */}
      <p className="subtitle">Ajoutez votre signature sur vos documents</p>

      <div className="buttons-container">
        <button type="button" className={`tab-button ${!imageData ? 'active' : ''}`} onClick={() => clearSignature()}>
          Dessiner
        </button>
        <button type="button" className={`tab-button ${imageData ? 'active' : ''}`} onClick={() => { clearSignature(); document.getElementById("upload-image").click() }}>
          Télécharger une image
        </button>
        <input
          type="file"
          id="upload-image"
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />

      </div>

      <div className="signature-box">
        {imageData ? (
          <img
            src={imageData}
            alt="Signature"
            className="uploaded-signature"
          />
        ) : (
          <SignatureCanvas
            ref={sigCanvas}
            canvasProps={{ width: 245, height: 170, className: "sigCanvas" }}
            onEnd={saveSignature}
          />
        )}
      </div>

      <div className="action-buttons">
        {/* <button className="save-button" type="button"  onClick={saveSignature}>Enregistrer la signature</button>  */}
        <button className="clear-button" type="button" onClick={clearSignature}>Effacer</button>
      </div>

      {/* {imageData && (
        <div className="saved-signature-container">
          <h3 className="saved-signature-title">Signature enregistrée :</h3>
          <img src={imageData} alt="Saved signature" className="saved-signature" />
        </div>
      )} */}
    </div>
  );
};

export default SignatureScreen;

import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  text: {
    fontSize: 10,
    fontWeight: 400,
  },
});

const InvoiceNo = ({ item }) => {
  return (
    <View>
      <Text style={styles.text}>{`Devis N° ${item.reference}`}</Text>
    </View>
  );
}
export default InvoiceNo;

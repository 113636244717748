import useFetchUser from "hooks/useFetchUser";
import React, { useEffect, useState, useRef } from "react";
import Carousel from "components/Carousel/Carousel";
import { Container } from "reactstrap";
import GenericHeader from "components/Headers/GenericHeader";
import Quotes from "./Quotes";
import Quote from "./Quote";
import { isObjectEmpty } from "utils/objects";
import { Downloader } from "components/Pdf/downloader";
import { getformatedDate } from "utils/_helpers/dateUtils";
import { getTime } from "utils/date";
import Preview from "./preview";
import { NotificationManager } from "react-notifications";
import { quoteService } from "utils/_services/quote.services";

const quotesSlideIndex = 0;
const quoteSlideIndex = 1;
const Main = () => {

  const user = useFetchUser();

  const [quotes, setQuotes] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const [activeSlide, setActiveSlide] = useState(
    id ? quoteSlideIndex : quotesSlideIndex
  );
  const nextSlide = () =>
    setActiveSlide(activeSlide < 1 ? activeSlide + 1 : activeSlide);
  const previousSlide = () =>
    setActiveSlide(activeSlide > 0 ? activeSlide - 1 : activeSlide);

  const onRowClick = (index) => {
    setSelectedQuote(enrichQuote(quotes[index]));
    setSelectedIndex(index);
    nextSlide();
  };

  useEffect(() => {
    try {
      if (user) {
        loadData(user.id);
      }
    } catch (error) {
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    try {
      if (user && activeSlide === quotesSlideIndex) {
        loadData(user.id);
      }
    } catch (error) {
    }
    // eslint-disable-next-line
  }, [activeSlide]);

  const loadData = (userId) => {
    if (id) {
      quoteService.getQuoteById(id).then(response => {
        setSelectedQuote(enrichQuote(response.data));
      });
    }

    quoteService.getAllQuotesByUserId(userId)
      .then(response => {
        setQuotes(response.data);
      });
  };


  const enrichQuote = (item) => {
    return {
      ...item,
      ...{
        commercialName: user.company?.name,
        address: user.company?.address,
        mail: user.mail,
        phone: user.phoneNumber,
        siret: user.company?.siretNumber,
        tvaNumber: user.company?.tvaNumber ?? "non fournie",
        logo: user.company?.logo?.url,
        signature: user.company?.signature?.url,
      }
    };
  }

  const onAddClick = async (index, item) => {
    let initItem = {};
    if (index > -1) {
      initItem = quotes[index];
    } else {
      initItem.priceHt = 0;
      initItem.penaltyRate = 0;
      initItem.currency = "€";
      initItem.tva = 20;
      initItem.includeTva = true;
      initItem.includeLogo = true;
      initItem.includePenalty = false;
      initItem.state = "unpaid";
      initItem.reference = item.reference;
      initItem.clientName = "";
      initItem.clientAddress = "";
      initItem.serviceExecutionDate = getformatedDate();
      initItem.creationDate = getformatedDate();
      initItem.quantity = 0;
      initItem.totalPrice = 0;
      initItem.lines = [];
    }
    setSelectedQuote(enrichQuote(initItem));
    setSelectedIndex(index);
    nextSlide();
  };

  const generatePdf = (item) => {
    const completeItem = enrichQuote(item);
    return { title: `Devis-${completeItem.commercialName}-${getTime()}.pdf`, page: <Preview item={completeItem} /> };
  }

  const onDownload = async (item) => {
    Downloader(generatePdf(item))
  }

  const onSaveClick = async (index, data) => {
    try {
      //   // dispatch(setLoading(true));
      if (index === -1) {
        await quoteService.addQuote(data);
        NotificationManager.success(
          "Le devis a été ajoutée avec succes"
        );
      } else {
        await quoteService.updateQuote(data);
        NotificationManager.success(
          "L devis a été mis à jour avec succes"
        );
      }

    } catch (e) {
      console.log(e.message);
    }
    finally {

    }
  };

  const items = [
    {
      id: quotesSlideIndex,
      component: (
        <Quotes
          data={quotes}
          handleRowClick={onRowClick}
          handleInit={onAddClick}
          //   handleDeleteClick={onDeleteClick}
          handleDownload={onDownload}
          generatePdf={generatePdf}
          handleSave={onSaveClick}
        />
      ),
    },
    {
      id: quoteSlideIndex,
      component: !isObjectEmpty(selectedQuote) && (
        <Quote
          item={selectedQuote}
          handleBackClick={previousSlide}
          index={selectedIndex}
          handleSave={onSaveClick}
        />
      ),
    },
  ];

  return (<>
    <GenericHeader />
    <Container style={{ maxWidth: "2000px", marginTop: "25px" }}>
      <Carousel items={items} activeIndex={activeSlide} />
    </Container>
  </>)
}

export default Main; 
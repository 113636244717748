import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getReactionImageBytype } from "../common";
import { reactionColor } from "utils/constants";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { reactionText } from "utils/constants";
import { Button } from "reactstrap";

export default function ReactionButton({ handleChangeUserReaction, handleMouseEnter, handleMouseLeaveLike, reaction }) {

    const getReaction = () => {
        if (reaction === null) {
            return (<>
                <FontAwesomeIcon icon={faThumbsUp} className="mr-2" />
                J'aime
            </>
            );
        }

        return (
            <>
                <img src={getReactionImageBytype(reaction)} alt={reactionText[reaction]} className="mr-2" style={{ width: "22px", height: "22px" }} />
                <span style={{ color: reactionColor[reaction] }}>{reactionText[reaction]}</span>
            </>
        );
    };

    return (<Button
        color="link"
        onClick={() => { handleChangeUserReaction(null) }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeaveLike}
        className="black-a75 btn-hover-gray btn social-btn"
    >
        {getReaction()}
    </Button>);

} 
import { useEffect, useState } from "react";
import { Box, styled, Tab, Tabs } from "@material-ui/core";
import CustomTabPanel from "components/Tab/CustomTabPanel";
import ReactionUser from "./ReactionUser";

export function PostReactionsComponent(props) {
    const { reactions } = props;

    const [usersReactions, setUsersReactions] = useState(reactions);

    useEffect(() => {
       const sortedReactions = [...reactions].sort((a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate));
        setUsersReactions(sortedReactions);
    }, [reactions]);

    const [selectedTabValue, setSelectedTabValue] = useState(0);

    const handleTabChange = (event, newSetSelectedTabValue) => {
        setSelectedTabValue(newSetSelectedTabValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const CustomTab = styled(Tab)(({ color }) => ({
        textTransform: "none", // Make text not capitalized
        color: color || "#000", // Default color to black if no color prop is provided
        minWidth: "auto", // Fit the tab width to content
        fontSize: "14px",
        fontWeight: "bold",
        "&:hover": {
            backgroundColor: "#f0f0f0", // Set the background color on hover
        },
    }));

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    variant="scrollable"
                    className="w-100"
                    value={selectedTabValue}
                    onChange={handleTabChange}
                    aria-label="reactions tabs"
                >
                    <CustomTab label={`Tous ${reactions.length}`} {...a11yProps(0)} color={"#F50057"} />
                    {/* <CustomTab label={<div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={LikeEmojiCircle} // Example image URL
                            alt="like"
                            style={{ marginRight: 2 }} // Spacing between image and text
                            width={40}
                            height={40}
                        />
                        1
                    </div>} {...a11yProps(1)} /> */}
                </Tabs>
            </Box>
            <CustomTabPanel style={{ maxHeight: "370px", "overflow": "auto" }} value={selectedTabValue} index={0}>
                <div className="border-top">
                    {usersReactions.map((reaction) => <ReactionUser key={reaction.id} reaction={reaction} />)}
                </div >
            </CustomTabPanel>
            {/* <CustomTabPanel style={{ maxHeight: "370px", "overflow": "auto" }} value={selectedTabValue} index={1}>
                test
            </CustomTabPanel> */}
        </Box>
    );
}
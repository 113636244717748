import { Media } from "reactstrap"
import { getReactionImageCircleBytype } from "../common";

export default function ReactionUser(props) {
    const { key, reaction } = props;

    return (
        <Media key={key} className="border-bottom p-2">
            <Media left>
                <Media style={{ position: "relative", display: "inline-block" }} left className="align-self-center">
                    <div style={{ position: "relative", display: "inline-block" }}>
                        <img
                            alt="profil"
                            className="avatar"
                            src={reaction.user.photoUrl ?? "https://images.assetsdelivery.com/compings_v2/triken/triken1608/triken160800029.jpg"}
                            style={{ width: "50px", height: "50px", borderRadius: "50%" }} // Adjust size and border as needed
                        />
                        <img
                            src={getReactionImageCircleBytype(reaction.reactionType)}
                            alt="J'aime"
                            style={{
                                width: "22px",
                                height: "22px",
                                position: "absolute",
                                bottom: "-5px",
                                right: "-5px",
                            }}
                        />
                    </div>
                </Media>
            </Media>
            <Media body className="ml-2 mt-2 rounded">
                <div className="d-flex align-items-start">
                    <div className="pl-2">
                        <span className="d-flex text-dark font-weight-bold text-capitalize">
                            {reaction.user.firstName}{" "}
                            {reaction.user.lastName}
                        </span>
                        <span className="d-flex small">
                            {reaction.user.jobTitle}{reaction.user.companyName && (` chez ${reaction.user.companyName}`)}
                            {reaction.user.company?.name}
                        </span>
                    </div>
                </div>

            </Media>           
        </Media >
    )
}
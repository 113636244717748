import React, { useState } from "react";
import { ContentCopyIcon } from "../Icons/icons";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@mui/lab/Alert";

const Clipboard = (props) => {
    const [isCopied, setIsCopied] = useState(false);

    return props.value ? (
        <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={() => {
                navigator.clipboard.writeText(props.value);
                setIsCopied(true);
            }}
        >
            {props.children}
            <ContentCopyIcon style={{ ...{ paddingLeft: 2 }, ...props.style }} />
            {isCopied && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={isCopied}
                    onClose={() => setIsCopied(false)}
                    autoHideDuration={700}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        open={isCopied}
                        onClose={() => setIsCopied(false)}
                        severity={"success"}
                        sx={{ width: "100%" }}
                    >
                        {"Copié"}
                    </MuiAlert>
                </Snackbar>
            )}
        </div>
    ) : (
        props.children
    );
};

export default Clipboard;

import React, { useEffect, useState } from 'react';
import ReviewList from 'components/Review/ReviewList';
import { reviewsService } from 'utils/_services/review.service';

function CompanyReviews(props) {
  const { companyId } = props;
  const [reviews, setReviews] = useState([]);
  const [reviewsAverage, setReviewsAverage] = useState(0);

  useEffect(() => {
    reviewsService
      .getReviewsByCompanyId(companyId)
      .then((response) => {
        const companyReviews = response.data;
        setReviews(companyReviews);
        if (companyReviews.length > 0) {
          const avg = companyReviews.reduce((a, b) => a + b.rating, 0) / companyReviews.length;
          const avgRounded = avg.toFixed(1);
          setReviewsAverage(avgRounded)
        }
      }
      )
      .catch((err) => console.log(err));
  }, [companyId]);

  return (
    <>
      {reviewsAverage > 0 &&
        <div className='text-center'>
          <u>
            <span className="review-stars">{'★'}</span>
            <span><b>{reviewsAverage} - {reviews.length} Avi(s)</b></span>
          </u>
        </div>
      }
      {reviews && <ReviewList reviews={reviews} />}
    </>
  );
}

export default CompanyReviews;

import React from "react";
import {  Media } from "reactstrap";
import ReactionButtonWithSelector from "./reaction/ReactionButtonWithSelector";

const Comment = ({ postId, postComment }) => (
  <Media className="mb-2 mt-2 ext-empty">
    <Media left>
      <Media left className="align-self-center mt-2 ml-3">
        <img
          id={postComment.id}
          alt="profil"
          className="avatar"
          src={postComment.createdUser.photoUrl ?? "https://images.assetsdelivery.com/compings_v2/triken/triken1608/triken160800029.jpg"}
        />
      </Media>
    </Media>
    <Media body className="ml-1 mt-2 ">
      <div className="d-flex align-items-start">
        <div className="pl-2">
          <span
            className="d-flex text-dark font-weight-bold small text-capitalize"
            style={{ color: "rgb(0 0 0 / .9)" }}>
            {postComment.createdUser.firstName}{" "}
            {postComment.createdUser.lastName}
          </span>
          <span className="d-flex font-weight-bold text-xs  "
            style={{ color: "rgb(0 0 0 / .6)" }}
          >
            {postComment.createdUser.jobTitle}{postComment.createdUser.companyName && (` chez ${postComment.createdUser.companyName}`)}
          </span>
        </div>
      </div>
      <div className="pl-2 mb-1 text-dark small">
        {postComment.content && (
          <div className="saved-comment">
            {postComment.content.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </div>
        )}
        {/* <p className="">
          <span ><small className="black-a75">2h{postComment.crea}-</small></span>
          <span className="mr-2">
          <ReactionButtonWithSelector id={`comment-post${postId}-${postComment.id}`} reaction={null} handleChangeUserReaction={null} selectorStyle={{transform: "translateY(-112px)"}}/> </span>
        </p> */}
      </div>
    </Media>
    {/* <i className="fa fa-times justify-content-center align-self-center ml-2 cursor-pointer" ></i>    */}
  </Media>
);

export { Comment };

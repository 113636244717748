
import React from 'react'
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from 'reactstrap';
import { Add } from '@material-ui/icons';

export default function ButtonAddons(props) {
    const { addonText, addonTooltip, handleAddon } = props;
    return (
        <>
            <Tooltip title={addonTooltip ? addonTooltip : "Add"}>
                <Button
                    style={{
                        marginLeft: "10px",
                        paddingTop: "0px",
                        paddingRight: "10px",
                        paddingBottom: "0px",
                        paddingLeft: "10px",
                        backgroundColor: "#e9ecef",
                        color: "#495057",
                    }}
                    onClick={handleAddon ? () => handleAddon() : false}
                >
                    <Add />
                </Button>
            </Tooltip>
            {addonText}
        </>
    )
}


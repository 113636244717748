import React, { useEffect, useState } from "react";
import { Comment } from "./Comment";
import { Button, Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { socialService } from "utils/_services/social.services";

import './PostActions.css';
import useFetchUser from "hooks/useFetchUser";
import { reactionType } from "utils/constants";
import { Dialog } from "components/Dialogs/Dialog";
import { PostReactionsComponent } from "./reaction/PostReactionsComponent";
import ReactionButtonWithSelector from "./reaction/ReactionButtonWithSelector";
import { PostReactionsCountComponent } from "./reaction/PostReactionsCountComponent";
import { post } from "jquery";

const PostActions = ({ postPub }) => {
  let addCommentInput;
  const [isOpen, setIsOpen] = useState(false);

  const [reactionModal, setReactionModal] = useState(false);
  const [reactions, setReactions] = useState(postPub.reactions);
  const [comments, setComments] = useState(postPub.comments);
  const [comment, setComment] = useState("");
  const [userReaction, setUserReaction] = useState(null);

  const user = useFetchUser();

  useEffect(() => {
    setUserReaction(null);
    if (postPub && user && postPub.reactions?.some((reaction) => reaction.user?.id === user.id)) {
      const existingUserReaction = postPub.reactions.find((reaction) => reaction.user.id === user.id).reactionType;
      const reactionName = reactionType[existingUserReaction];
      setUserReaction(reactionName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postPub, user]);

  const toggleReactionModal = () => setReactionModal(!reactionModal);

  const toggle = () => setIsOpen(!isOpen);


  const handleChangeUserReaction = (reaction) => {
    setUserReaction(reaction);

    if (reaction === null) {
      socialService.deleteUserReaction(postPub.id);
      postPub.reactions = postPub.reactions.filter((reaction) => reaction.user.id !== user.id);
      setReactions(postPub.reactions);
      return;
    }

    socialService
      .changeUserReaction({ postId: postPub.id, reactionType: reaction, userId: user.id })
      .then((response) => {
        postPub.reactions = postPub.reactions.filter((reaction) => reaction.user.id !== user.id).concat(response.data);
        setReactions(postPub.reactions);
      });
  };


  const commentClick = () => {
    setIsOpen(true);
    addCommentInput.focus();
  };

  const handleInput = (e) => {
    setComment(e.target.value);
    resizeTextarea(e.target);
  };

  const resizeTextarea = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  function sendComment(e) {
    e.preventDefault();
    if (comment === "") {
      NotificationManager.error("Le commentaire ne doit pas être vide.");
      return;
    }

    const data = {
      content: comment,
      postId: postPub.id.toString(),
    };

    socialService
      .addComment(data)
      .then((response) => {
        if (response !== undefined) {
          const searchCommentsdata = {
            postId: postPub.id.toString(),
          };

          socialService
            .searchComments(searchCommentsdata)
            .then((searchResponse) => {
              setComments(searchResponse.data);
            });
          NotificationManager.success("Commentaire ajouté avec succes");
          setComment("");
        } else {
          NotificationManager.error("Problème lors de l'ajout du commentaire");
        }
      })
      .catch((err) =>
        NotificationManager.error("Problème lors de l'ajout du commentaire")
      );
  }

  return (
    <>
      <div className="border-bottom ml-2">
        <PostReactionsCountComponent reactions={reactions} onReactionClick={toggleReactionModal} />
      </div>

      <div className="mb-2 social-actions">
        <ReactionButtonWithSelector id={`selector-post${postPub.id}`} reaction={userReaction} handleChangeUserReaction={handleChangeUserReaction} />
        <Button
          onClick={commentClick}
          color="link"
          className="black-a75 btn-hover-gray social-btn"
        >
          <FontAwesomeIcon icon={faComment} className="mr-2" />
          Commenter
        </Button>
        <Button
          onClick={toggle}
          color="link"
          className="black-a75 text-decoration-underline btn-hover-gray social-btn"
        >
          <u> {comments ? comments.length : 0} Commentaire(s)</u>
        </Button>
      </div>

      {/* Comments section */}
      <Collapse isOpen={isOpen}>
        <div className="px-3">
          {/* <div className="input-group" style={{ width: "95%" }}>         */}
          <textarea
            placeholder="Ajouter un commentaire..."
            className="form-control"
            value={comment}
            ref={addCommentInput}
            onInput={handleInput}
            name="messageContent"
            rows="2"
            style={{ resize: 'none', overflow: 'hidden' }}
          />
          {/* <span class="mr-2 mt-4 badge badge-primary float-right position-relative"> */}
          <Button
            color="primary"
            size="sm"
            className="mr-2 mt-n5  float-right position-relative"
            style={{ cursor: comment.trim() === "" ? "not-allowed" : "pointer" }}
            disabled={comment.trim() === ""}
            type="button"
            onClick={sendComment}
          >
            Commenter
          </Button>
        </div>
        {/* </div> */}
        {comments
          ? comments.map((comment, index) => (
            <Comment postId={postPub.id} postComment={comment} />
          ))
          : null}
      </Collapse>

      {reactionModal && (
        <Dialog
          title="Reactions"
          centered={false}
          component={<PostReactionsComponent reactions={reactions} />}
          handleClose={toggleReactionModal}
        />
      )}


      <NotificationContainer />
    </>
  );
};

export { PostActions };
